import React, { Component } from 'react';

class AboutMe extends Component {

    render() {
        return (
            <div>
                <h1>Not Found</h1>
            </div>
        )
    }
}

export default AboutMe;