import React, { Component } from 'react';
import CanvasJS from './canvasjs.react';
var CanvasJSChart = CanvasJS.CanvasJSChart;

class StylesChart extends Component {
    render() {
        let stylesData = null;
        if (this.props.data) {
            stylesData = this.props.data.map((dt, index) => {
                return { label: dt.St, y: dt.Ct, color: "Indigo", click: () => this.props.onStyleClick(dt.St) }
            });
        }

        const options = {
            animationEnabled: true,
            zoomEnabled: true,
            title: {
                text: "Beers by Style"
            },
            axisY: {
                includeZero: true
            },
            data: [{
                type: "column",
                dataPoints: stylesData
            }]
        }

        return (
            <div>
                <CanvasJSChart options={options} />
            </div>
        );
    }
}

export default StylesChart;