import React, { Component } from 'react';
import CanvasJS from './canvasjs.react';
var CanvasJSChart = CanvasJS.CanvasJSChart;

class TimesChart extends Component {
    render() {
        let timesData = null;
        if (this.props.data) {
            timesData = this.props.data.map((dt, index) => {
                return { x: new Date(null,null,null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo" }
            });
        }

        const timesOptions = {
            animationEnabled: true,
            title:{
                text: "Number of Beers by Hour"
            },
            axisX: {
                title: "Time of Day",
                valueFormatString: "H:mm"
            },
            axisY: {
                includeZero: false
            },
            data: [{
                yValueFormatString: "#",
                xValueFormatString: "H:mm",
                type: "spline",
                dataPoints: timesData
            }]
        }

        return (
            <div>
                <CanvasJSChart options = {timesOptions} />
            </div>
        );
    }
}

export default TimesChart;