import React, { Component } from 'react';
import styles from "./FilterButton.module.css";

class FilterButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div id="FilterCtrl" className={this.props.isSidebarOpen ? styles.filterControlOpen : styles.filterControlClosed}>
                <a id="FilterBtn" href={() => false} className={this.props.isSidebarOpen ? styles.filterButtonOpen : styles.filterButtonClosed} onClick={this.props.toggleSidebar}>Filter</a>
            </div>
        );
    }
}

export default FilterButton;