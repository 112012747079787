import React, {Component} from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Checkin.module.css';

class Checkin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            currentIndex: 0,
        }
    }

    handlePrevious = () => {
        this.setState((state) => ({
            currentIndex: (state.currentIndex - 1 + this.props.checkins[1].length) % this.props.checkins[1].length,
        }));
    };

    handleNext = () => {
        this.setState((state) => ({
            currentIndex: (state.currentIndex + 1) % this.props.checkins[1].length,
        }));
    };

    handleNavigate = (index) => {
        this.setState({ currentIndex: index });
    };

    render() {
        return(
            <Carousel showIndicators={false} showThumbs={false}>
                {this.props.checkins[1].map((checkin, key) => (
                    <div key={key}>
                        <h3>{checkin.Location}</h3>
                        <p><b>{checkin.Beer}</b> by <b>{checkin.Brewery}</b></p>
                        <p>{new Date(checkin.Date).toLocaleString()}</p>
                        <img src={"https://rjbogz.com/pictures/"+checkin.CheckinID+".jpg"} alt={checkin.CheckinID} />
                        <p>{checkin.Notes}</p>
                    </div>
                ))}
            </Carousel>
        );
    }
}

export default Checkin;