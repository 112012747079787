import React, { Component } from 'react';

class DateFilter extends Component {
    constructor(props) {
        super(props);
        const today = new Date().toISOString().split('T')[0];
        this.state = {
            startDate: null,
            endDate: today,
        };
    }

    componentDidMount() {
        fetch('https://rjbogz.com/api/beer/min')
            .then(response => response.json())
            .then(data => {
                this.setState({
                    startDate: data.lcsc[0].d,
                }, () => {
                    this.props.onDateChange(this.state.startDate, this.state.endDate);
                });
            })
            .catch(error => {
                console.error("Error fetching earliest check-in date:", error);
            });
    }

    handleInputChange = () => {
        const startDate = this.refs.startDate.value;
        const endDate = this.refs.endDate.value;
        this.setState({ startDate, endDate }, () => {
            this.props.onDateChange(startDate, endDate);
        });
    };

    render() {
        const { startDate, endDate } = this.state;
        return (
            <div>
                <input type="date" ref="startDate" onChange={this.handleInputChange} value={startDate || ''} />
                to
                <input type="date" ref="endDate" onChange={this.handleInputChange} value={endDate} />
            </div>
        );
    }
}

export default DateFilter;
