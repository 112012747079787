import React, { Component } from 'react';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: ["https://rjbogz.com/beer/images/glassempty.png", "https://rjbogz.com/beer/images/glasshalf.png", "https://rjbogz.com/beer/images/glassfull.png"],
            idx: 0
        }
    }

    componentDidMount() {
        setInterval(() => {
            let i = this.state.idx>=2 ? 0 : this.state.idx+1;
            this.setState({ idx: i })
        }, 333);
    }

    render() {
        return (
            <div>
                <img style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} width="20%" src={this.state.src[this.state.idx]} alt="loading" />
            </div>
        )
    }
}

export default Loading;