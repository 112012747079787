import React, { Component } from 'react';
import styles from './SideBar.module.css';
import DateFilter from "./DateFilter";
import BeerFilter from "./BeerFilter";
import LocationFilter from "./LocationFilter";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: "Beers",
            startDate: "",
            endDate: "",
            selectAllChecked: true,
            selectAllIndeterminate: false,
            isStylesExpanded: false,
            isRatingsExpanded: false,
        };
        this.changeTab = this.changeTab.bind(this);
        this.selectAllCheckbox = React.createRef();
        this.toggleStyles = this.toggleStyles.bind(this);
        this.toggleRatings = this.toggleRatings.bind(this);
    }

    componentDidMount() {
        this.updateCheckboxIndeterminate();
        if (this.props.filterType && this.props.filterValue) {
            this.applyFilter(this.props.filterType, this.props.filterValue);
        }
    }

    componentDidUpdate(prevProps) {
        this.updateCheckboxIndeterminate();
        if (prevProps.filterType !== this.props.filterType || prevProps.filterValue !== this.props.filterValue) {
            this.applyFilter(this.props.filterType, this.props.filterValue);
        }
    }

    updateCheckboxIndeterminate() {
        if (this.selectAllCheckbox.current) {
            this.selectAllCheckbox.current.indeterminate = this.state.selectAllIndeterminate;
        }
    }

    applyFilter(filterType, filterValue) {
        if (filterType === 'style') {
            this.props.onStyleChange([filterValue]);
            this.setState({ isStylesExpanded: true });
        } else if (filterType === 'rating') {
            this.props.onRatingChange([filterValue]);
            this.setState({ isRatingsExpanded: true });
        }
    }

    crossCheckBeersLocations(type, key) {
        let { beerSelections, locationSelections, locationBeerMap, beerLocationMap } = { ...this.props };
        if (type === 'location' && key) {
            locationBeerMap[key].forEach(beer => {
                if (beerLocationMap[beer].size > 1) {
                    const totalLocations = beerLocationMap[beer].size;
                    let checkedLocations = 0;
                    beerLocationMap[beer].forEach(location => {
                        if (locationSelections[location].checked) {
                            checkedLocations += 1;
                        }
                    });
                    if (checkedLocations === 0) {
                        beerSelections[beer].checked = false;
                        beerSelections[beer].indeterminate = false;
                    } else if (totalLocations === checkedLocations) {
                        beerSelections[beer].checked = true;
                        beerSelections[beer].indeterminate = false;
                    } else if (totalLocations > checkedLocations && checkedLocations !== 0) {
                        beerSelections[beer].checked = false;
                        beerSelections[beer].indeterminate = true;
                    }
                } else {
                    beerSelections[beer].checked = locationSelections[key].checked;
                    beerSelections[beer].indeterminate = false;
                }
                beerSelections = this.updateRecursively(beerSelections, "beer", beer);
            });
            this.props.setBeerSelections(beerSelections);
        } else if (type === 'beer' && key) {
            beerLocationMap[key].forEach(location => {
                if (locationBeerMap[location].size > 1) {
                    const totalBeers = locationBeerMap[location].size;
                    let checkedBeers = 0;
                    locationBeerMap[location].forEach((beer, beerAgain, set) => {
                        if (beerSelections[beer].checked) {
                            checkedBeers += 1;
                        }
                    });
                    if (checkedBeers === 0) {
                        locationSelections[location].checked = false;
                        locationSelections[location].indeterminate = false;
                    } else if (totalBeers === checkedBeers) {
                        locationSelections[location].checked = true;
                        locationSelections[location].indeterminate = false;
                    } else if (totalBeers > checkedBeers && checkedBeers !== 0) {
                        locationSelections[location].checked = false;
                        locationSelections[location].indeterminate = true;
                    }
                } else {
                    locationSelections[location].checked = beerSelections[key].checked;
                    locationSelections[location].indeterminate = false;
                }
                locationSelections = this.updateRecursively(locationSelections, "location", location);
            });
            this.props.setLocationSelections(locationSelections);
        }
    }

    updateRecursively = (newSelections, type, key) => {
        if (type === "beer") {
            const [brewery] = key.split('|');
            const allBeersOfBrewery = Object.keys(newSelections).filter(k => k.startsWith(`${brewery}|`));
            const totalBeersOfBrewery = allBeersOfBrewery.length;
            let checkedBeersOfBrewery = 0;
            let indeterminateChildren = 0;
            allBeersOfBrewery.forEach(beerKey => {
                if (newSelections[beerKey].indeterminate) {
                    indeterminateChildren += 1;
                }
                if (newSelections[beerKey].checked || newSelections[beerKey].indeterminate) {
                    checkedBeersOfBrewery += 1;
                }
            });
            if (checkedBeersOfBrewery === 0) {
                newSelections[brewery].checked = false;
                newSelections[brewery].indeterminate = false;
            } else if ((totalBeersOfBrewery > checkedBeersOfBrewery && checkedBeersOfBrewery !== 0) || (indeterminateChildren > 0)) {
                newSelections[brewery].checked = false;
                newSelections[brewery].indeterminate = true;
            } else if (totalBeersOfBrewery === checkedBeersOfBrewery) {
                newSelections[brewery].checked = true;
                newSelections[brewery].indeterminate = false;
            }
            return newSelections;
        } else if (type === "location" || type === "city" || type === "state") {
            const parent = this.getParent(key);
            const allChildren = Object.keys(newSelections).filter(k => k.startsWith(`${parent === "ISACOUNTRY" ? key : parent}|`));
            const totalChildren = allChildren.length;
            let checkedChildren = 0;
            let indeterminateChildren = 0;
            allChildren.forEach(childKey => {
                if (newSelections[childKey].indeterminate) {
                    indeterminateChildren += 1;
                }
                if (newSelections[childKey].checked || newSelections[childKey].indeterminate) {
                    checkedChildren += 1;
                }
            });
            if (checkedChildren === 0) {
                newSelections[parent === "ISACOUNTRY" ? key : parent].checked = false;
                newSelections[parent === "ISACOUNTRY" ? key : parent].indeterminate = false;
            } else if ((totalChildren > checkedChildren && checkedChildren !== 0) || (indeterminateChildren > 0)) {
                newSelections[parent === "ISACOUNTRY" ? key : parent].checked = false;
                newSelections[parent === "ISACOUNTRY" ? key : parent].indeterminate = true;
            } else if (totalChildren === checkedChildren) {
                newSelections[parent === "ISACOUNTRY" ? key : parent].checked = true;
                newSelections[parent === "ISACOUNTRY" ? key : parent].indeterminate = false;
            }
            if (parent === "ISACOUNTRY") {
                return newSelections;
            } else {
                return this.updateRecursively(newSelections, type, parent);
            }
        }
        this.updateSelectAll();
        return newSelections;
    };

    updateSelectAll() {
        const { beerSelections, locationSelections } = this.props;
        let allChecked = true;
        let allUnchecked = true;

        Object.values(beerSelections).forEach(selection => {
            if (!selection.checked) {
                allChecked = false;
            }
            if (selection.checked || selection.indeterminate) {
                allUnchecked = false;
            }
        });
        Object.values(locationSelections).forEach(selection => {
            if (!selection.checked) {
                allChecked = false;
            }
            if (selection.checked || selection.indeterminate) {
                allUnchecked = false;
            }
        });

        this.setState({
            selectAllChecked: allChecked ? true : false,
            selectAllIndeterminate: !allChecked && !allUnchecked
        });
    }

    handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        let newBeerSelections = { ...this.props.beerSelections };
        let newLocationSelections = { ...this.props.locationSelections };

        Object.keys(newBeerSelections).forEach(beerKey => {
            newBeerSelections[beerKey].checked = isChecked;
            newBeerSelections[beerKey].indeterminate = false; // Clear indeterminate state
        });
        Object.keys(newLocationSelections).forEach(locationKey => {
            newLocationSelections[locationKey].checked = isChecked;
            newLocationSelections[locationKey].indeterminate = false; // Clear indeterminate state
        });

        this.props.setBeerSelections(newBeerSelections);
        this.props.setLocationSelections(newLocationSelections);

        this.setState({
            selectAllChecked: isChecked,
            selectAllIndeterminate: false // Clear indeterminate state when manually toggled
        });
    };

    getParent(str) {
        const parts = str.split('|');
        if (parts.length > 1) {
            parts.pop();
        } else {
            return "ISACOUNTRY"
        }
        return parts.join('|');
    }

    updateSelections = (type, key, value) => {
        if (type === "beer" || type === "brewery") {
            let newBeerSelections = { ...this.props.beerSelections };
            if (type === "beer") {
                newBeerSelections[key].checked = value;
                newBeerSelections[key].indeterminate = false;
                newBeerSelections = this.updateRecursively(newBeerSelections, type, key);
                this.crossCheckBeersLocations("beer", key);
            } else if (type === "brewery") {
                const beersOfBrewery = Object.keys(newBeerSelections).filter(k => k.startsWith(`${key}|`));
                newBeerSelections[key].checked = value;
                newBeerSelections[key].indeterminate = false;
                beersOfBrewery.forEach(beerKey => {
                    newBeerSelections[beerKey].checked = value ;
                    newBeerSelections[beerKey].indeterminate = false ;
                    this.crossCheckBeersLocations("beer", beerKey);
                });
            }
            this.props.setBeerSelections(newBeerSelections);
        } else if (type === "location" || type === "city" || type === "state" || type === "country") {
            let newLocationSelections = { ...this.props.locationSelections };
            if (type === "location") {
                newLocationSelections[key].checked = value;
                newLocationSelections[key].indeterminate = false;
                newLocationSelections = this.updateRecursively(newLocationSelections, type, key);
                this.crossCheckBeersLocations("location", key);
            } else if (type === "city" || type === "state") {
                const children = Object.keys(newLocationSelections).filter(k => k.startsWith(`${key}|`));
                newLocationSelections[key].checked = value;
                newLocationSelections[key].indeterminate = false;
                children.forEach(childKey => {
                    newLocationSelections[childKey].checked = value;
                    newLocationSelections[childKey].indeterminate = false;
                    if (childKey.split('|').length >= 4) {
                        this.crossCheckBeersLocations("location", childKey);
                    }
                });
                newLocationSelections = this.updateRecursively(newLocationSelections, type, key);
            } else if (type === "country") {
                const childrenOfCountry = Object.keys(newLocationSelections).filter(k => k.startsWith(`${key}|`));
                newLocationSelections[key].checked = value;
                newLocationSelections[key].indeterminate = false;
                childrenOfCountry.forEach(childKey => {
                    newLocationSelections[childKey].checked = value;
                    newLocationSelections[childKey].indeterminate = false;
                    if (childKey.split('|').length >= 4) {
                        this.crossCheckBeersLocations("location", childKey);
                    }
                });
            }
            this.props.setLocationSelections(newLocationSelections);
            this.crossCheckBeersLocations("location");
        }
        this.updateSelectAll();
    }

    handleLocationFilterChange = (filteredLocations) => {
        this.setState({ filteredLocations }, this.updateDisplayedMarkers);
    };

    toggleStyles() {
        this.setState(prevState => ({ isStylesExpanded: !prevState.isStylesExpanded }));
    }

    toggleRatings() {
        this.setState(prevState => ({ isRatingsExpanded: !prevState.isRatingsExpanded }));
    }

    changeTab(event) {
        if (event.target.id === "beerLabel") {
            this.setState({ checked: "Beers" });
        }
        if (event.target.id === "locationLabel") {
            this.setState({ checked: "Locations" });
        }
    }

    render() {
        const { locationsHierarchy, locationSelections, beersHierarchy, beerSelections, isSidebarOpen, styles: styleOptions, onStyleChange, ratings, onRatingChange } = this.props;
        const { isStylesExpanded, isRatingsExpanded } = this.state;
        return (
            <div id="sidebar" className={styles.sidebar} style={{ display: isSidebarOpen ? 'block' : 'none' }}>
                <div className={styles.center}>
                    <DateFilter onDateChange={this.props.onDateChange} />
                </div>
                <div className={styles.center}>
                    <button className={styles.expandButton} onClick={this.toggleStyles}>
                        {isStylesExpanded ? "Hide Styles" : "Show Styles"}
                    </button>
                    {isStylesExpanded && (
                        <div className={styles.expandedSection}>
                            <label>Styles</label>
                            <select multiple onChange={(e) => onStyleChange([...e.target.selectedOptions].map(option => option.value))}>
                                {styleOptions.map(style => (
                                    <option key={style} value={style}>{style}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                <div className={styles.center}>
                    <button className={styles.expandButton} onClick={this.toggleRatings}>
                        {isRatingsExpanded ? "Hide Ratings" : "Show Ratings"}
                    </button>
                    {isRatingsExpanded && (
                        <div className={styles.expandedSection}>
                            <label>Ratings</label>
                            <select multiple onChange={(e) => onRatingChange([...e.target.selectedOptions].map(option => option.value))}>
                                {ratings.map(rating => (
                                    <option key={rating} value={rating}>{rating}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                <div className={styles.layout}>
                    <label id="beerLabel" className={`${styles.nav} ${this.state.checked === "Beers" ? styles.checked : ''}`} onClick={this.changeTab}>
                        <span id="beerLabel" className={styles.navspan}>Beers</span>
                    </label>
                    <label id="locationLabel" className={`${styles.nav} ${this.state.checked === "Locations" ? styles.checked : ''}`} onClick={this.changeTab}>
                        <span id="locationLabel" className={styles.navspan}>Locations</span>
                    </label>
                </div>
                <div className={`${styles.tabsel} ${styles.selectAllContainer}`}>
                    <input
                        type="checkbox"
                        ref={this.selectAllCheckbox}
                        checked={this.state.selectAllChecked}
                        onChange={this.handleSelectAllChange}
                        className={styles.selectAllCheckbox}
                    />
                    <label className={styles.selectAllLabel}>
                        Select All/None
                    </label>
                </div>
                <div id="beerMenu" className={`${styles.tabsel} ${this.state.checked === "Beers" ? styles.show : styles.hidden}`}>
                    <BeerFilter updateSelections={this.updateSelections} beersHierarchy={beersHierarchy} beerSelections={beerSelections} />
                </div>
                <div id="locationMenu" className={`${styles.tabsel} ${this.state.checked === "Locations" ? styles.show : styles.hidden}`}>
                    <LocationFilter locationsHierarchy={locationsHierarchy} updateSelections={this.updateSelections} locationSelections={locationSelections} onFilterChange={this.handleLocationFilterChange} />
                </div>
            </div>
        );
    }
}

export default SideBar;
