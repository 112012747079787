import React, { Component } from 'react';
import MapComponent from './MapComponent';
import Loading from '../Loading';
import SideBar from "./Filter/SideBar";
import FilterButton from "./Filter/FilterButton";
import { useNavigate, useLocation } from 'react-router-dom';

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            full: null,
            display: null,
            infoboxMessage: null,
            isInfoboxVisible: false,
            markerLang: null,
            markerLat: null,
            isSidebarOpen: false,
            dataLoaded: false,
            locationsHierarchy: {},
            beersHierarchy: {},
            beerSelections: {},
            locationSelections: {},
            startDate: '',
            endDate: '',
            styles: [],
            selectedStyles: [],
            ratings: [],
            selectedRatings: [],
            filterType: null,
            filterValue: null
        };
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.setBeerSelections = this.setBeerSelections.bind(this);
        this.setLocationSelections = this.setLocationSelections.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleStyleChange = this.handleStyleChange.bind(this);
        this.handleRatingChange = this.handleRatingChange.bind(this);
        this.getFull = this.getFull.bind(this);
        this.updateDisplayedMarkers = this.updateDisplayedMarkers.bind(this);
    }

    getFull = () => {
        fetch('https://rjbogz.com/api/beer/full')
            .then(response => response.json())
            .then(response => {
                this.setState({
                    full: response.full,
                    display: response.full
                }, () => {
                    this.constructMaps();
                    this.buildHierarchies();
                    this.extractStylesAndRatings();
                    this.applyInitialFilter();
                });
            })
            .catch(err => console.log(err));
    }

    extractStylesAndRatings() {
        const { full } = this.state;
        const stylesSet = new Set();
        const ratingsSet = new Set();

        full.forEach(checkin => {
            stylesSet.add(checkin.Style);
            ratingsSet.add(checkin.Rating);
        });

        var styles = Array.from(stylesSet).sort();
        var ratings = Array.from(ratingsSet).sort();
        styles.unshift("ALL");
        ratings.unshift("ALL");

        this.setState({
            styles: styles,
            ratings: ratings
        });
    }

    applyInitialFilter() {
        const locationState = this.props.location.state;
        if (locationState && locationState.filterType && locationState.filterValue) {
            if (locationState.filterType === 'style') {
                this.setState({
                    selectedStyles: [locationState.filterValue]
                }, this.updateDisplayedMarkers);
            } else if (locationState.filterType === 'rating') {
                this.setState({
                    selectedRatings: [locationState.filterValue]
                }, this.updateDisplayedMarkers);
            }
        }
    }

    toggleSidebar() {
        this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));
    }

    setBeerSelections(newSelections) {
        this.setState({ beerSelections: newSelections });
    }

    setLocationSelections(newSelections) {
        this.setState({ locationSelections: newSelections });
    }

    handleDateChange(startDate, endDate) {
        this.setState({ startDate, endDate }, this.updateDisplayedMarkers);
    }

    handleStyleChange(selectedStyles) {
        if (selectedStyles.includes("ALL")) {
            this.setState({selectedStyles: this.state.styles});
        } else {
            this.setState({selectedStyles}, this.updateDisplayedMarkers);
        }
    }

    handleRatingChange(selectedRatings) {
        if (selectedRatings.includes("ALL")) {
            this.setState({selectedRatings: this.state.ratings});
        } else {
            this.setState({selectedRatings}, this.updateDisplayedMarkers);
        }
    }

    componentDidMount() {
        this.getFull();
        const locationState = this.props.location.state;
        if (locationState && locationState.filterType && locationState.filterValue) {
            this.setState({
                filterType: locationState.filterType,
                filterValue: locationState.filterValue,
                isSidebarOpen: true // Open the sidebar only when navigating from Stats
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.beerSelections !== this.state.beerSelections ||
            prevState.locationSelections !== this.state.locationSelections ||
            prevState.selectedStyles !== this.state.selectedStyles ||
            prevState.selectedRatings !== this.state.selectedRatings
        ) {
            this.updateDisplayedMarkers();
        }
    }

    constructMaps() {
        const { full } = this.state;
        let beerLocationMap = {};
        let locationBeerMap = {};

        if (!full) {
            return;
        }
        full.forEach(item => {
            let country, state, city = "";
            if (item.VenueCountry.trim() === '') {
                country = "Unknown Country";
            } else {
                country = item.VenueCountry;
            }
            if (item.VenueState.trim() === '') {
                state = "Unknown State";
            } else {
                state = item.VenueState;
            }
            if (item.VenueCity.trim() === '') {
                city = "Unknown City";
            } else {
                city = item.VenueCity;
            }
            const beerKey = `${item.Brewery}|${item.Beer}`;
            const locationKey = `${country}|${state}|${city}|${item.Latitude}^${item.Longitude}^${item.Location}`;

            if (!beerLocationMap[beerKey]) beerLocationMap[beerKey] = new Set();
            beerLocationMap[beerKey].add(locationKey);

            if (!locationBeerMap[locationKey]) locationBeerMap[locationKey] = new Set();
            locationBeerMap[locationKey].add(beerKey);
        });
        this.setState({
            beerLocationMap, locationBeerMap
        });
    }

    removeDuplicatesAndSort(type, data) {
        const uniqueData = {};
        if (type === 'location') {
            Object.keys(data).forEach(country => {
                const cleanCountry = country || "Unknown Country";
                uniqueData[cleanCountry] = {};

                Object.keys(data[country]).forEach(state => {
                    const cleanState = state || "Unknown State";
                    uniqueData[country][cleanState] = {};

                    Object.keys(data[country][state]).forEach(area => {
                        const cleanArea = area || "Unknown City";
                        const entries = data[country][state][area];

                        uniqueData[country][cleanState][cleanArea] = Array.from(new Set(entries)).sort();
                    });
                });
            });
        } else {
            const sortedKeys = Object.keys(data).sort((a, b) => a.localeCompare(b));
            sortedKeys.forEach(key => {
                uniqueData[key] = [...new Set(data[key].sort((a, b) => a.localeCompare(b)))];
            });
        }
        return uniqueData;
    }

    buildHierarchies() {
        const fullData = this.state.full;
        let locationsHierarchy = {};
        let locationSelections = {};
        let beersHierarchy = {};
        let beerSelections = {};

        if (!fullData) {
            return;
        }

        fullData.forEach(({CheckinID, Location, VenueCity, VenueState, VenueCountry, Latitude: latitude, Longitude: longitude }) => {
            let country, state, city = "";
            if (VenueCountry.trim() === '') {
                country = "Unknown Country";
            } else {
                country = VenueCountry;
            }
            if (VenueState.trim() === '') {
                state = "Unknown State";
            } else {
                state = VenueState;
            }
            if (VenueCity.trim() === '') {
                city = "Unknown City";
            } else {
                city = VenueCity;
            }

            if (!locationsHierarchy[country]) {
                locationsHierarchy[country] = {};
                locationSelections[country] = { checked: true, indeterminate: false };
            }
            if (!locationsHierarchy[country][state]) {
                locationsHierarchy[country][state] = {};
                locationSelections[`${country}|${state}`] = { checked: true, indeterminate: false };
            }
            if (!locationsHierarchy[country][state][city]) {
                locationsHierarchy[country][state][city] = [];
                locationSelections[`${country}|${state}|${city}`] = { checked: true, indeterminate: false };
            }

            let locationKey = `${country}|${state}|${city}|${latitude}^${longitude}^${Location}`;
            locationsHierarchy[country][state][city].push(locationKey);
            locationSelections[locationKey] = { id: CheckinID, checked: true, indeterminate: false };
        });

        locationsHierarchy = this.removeDuplicatesAndSort("location", locationsHierarchy);

        fullData.forEach(({ CheckinID, Beer, Brewery }) => {
            if (!beersHierarchy[Brewery]) {
                beersHierarchy[Brewery] = [];
                beerSelections[Brewery] = { checked: true, indeterminate: false };
            }
            beersHierarchy[Brewery].push(Beer);
            beerSelections[`${Brewery}|${Beer}`] = { id: CheckinID, checked: true, indeterminate: false };
        });

        beersHierarchy = this.removeDuplicatesAndSort("beer", beersHierarchy);

        this.setState({
            dataLoaded: true,
            locationSelections: locationSelections,
            locationsHierarchy: locationsHierarchy,
            beerSelections: beerSelections,
            beersHierarchy: beersHierarchy,
        });
    }

    updateDisplayedMarkers() {
        const { full, beerSelections, locationSelections, startDate, endDate, selectedStyles, selectedRatings } = this.state;
        if (!full) return;

        const selectedBeerIDs = new Set();
        Object.entries(beerSelections).forEach(([key, value]) => {
            if (value.checked) {
                selectedBeerIDs.add(value.id); // Assuming each entry in beerSelections contains an id
            }
        });

        const selectedLocationIDs = new Set();
        Object.entries(locationSelections).forEach(([key, value]) => {
            if (value.checked) {
                selectedLocationIDs.add(value.id); // Assuming each entry in locationSelections contains an id
            }
        });

        const displayedMarkers = full.filter(checkin => {
            const checkinDate = new Date(checkin.Date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            const isStyleMatch = selectedStyles.length === 0 || selectedStyles.includes(checkin.Style);
            const isRatingMatch = selectedRatings.length === 0 || selectedRatings.includes(checkin.Rating);
            return (selectedBeerIDs.has(checkin.CheckinID) || selectedLocationIDs.has(checkin.CheckinID)) &&
                checkinDate >= start && checkinDate <= end &&
                isStyleMatch && isRatingMatch;
        });

        this.setState({ display: displayedMarkers });
    }

    render() {
        const { locationsHierarchy, locationSelections, beersHierarchy, beerSelections, beerLocationMap, locationBeerMap, display, dataLoaded, isSidebarOpen, styles, ratings } = this.state;
        if (!dataLoaded) {
            return <Loading />;
        }
        return (
            <div>
                <MapComponent isMarkerShown FullArray={display} />
                <SideBar
                    locationsHierarchy={locationsHierarchy}
                    locationSelections={locationSelections}
                    beersHierarchy={beersHierarchy}
                    beerSelections={beerSelections}
                    beerLocationMap={beerLocationMap}
                    locationBeerMap={locationBeerMap}
                    setBeerSelections={this.setBeerSelections}
                    setLocationSelections={this.setLocationSelections}
                    isSidebarOpen={isSidebarOpen}
                    onDateChange={this.handleDateChange}
                    styles={styles}
                    onStyleChange={this.handleStyleChange}
                    ratings={ratings}
                    onRatingChange={this.handleRatingChange}
                    filterType={this.state.filterType}
                    filterValue={this.state.filterValue}
                />
                <FilterButton isSidebarOpen={isSidebarOpen} toggleSidebar={this.toggleSidebar} />
            </div>
        );
    }
}

const WrappedMap = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <Map {...props} navigate={navigate} location={location} />;
};

export default WrappedMap;
