import React, { useState, useEffect } from 'react';
import classes from './Stats.module.css';
import { useNavigate } from 'react-router-dom';
import DaysChart from './DaysChart';
import TimesChart from './TimesChart';
import StylesChart from "./StylesChart";
import RatingsChart from "./RatingsChart";

const Stats = () => {
    const [times, setTimes] = useState(null);
    const [days, setDays] = useState(null);
    const [timesByDays, setTimesByDays] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [styles, setStyles] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://rjbogz.com/api/stats/times')
            .then(response => response.json())
            .then(response => setTimes(response.data))
            .catch(err => console.log(err));

        fetch('https://rjbogz.com/api/stats/days')
            .then(response => response.json())
            .then(response => setDays(response.data))
            .catch(err => console.log(err));

        fetch('https://rjbogz.com/api/stats/timesbydays')
            .then(response => response.json())
            .then(response => setTimesByDays(response.data))
            .catch(err => console.log(err));

        fetch('https://rjbogz.com/api/stats/ratings')
            .then(response => response.json())
            .then(response => setRatings(response.data))
            .catch(err => console.log(err));

        fetch('https://rjbogz.com/api/stats/styles')
            .then(response => response.json())
            .then(response => setStyles(response.data))
            .catch(err => console.log(err));
    }, []);

    const handleStyleClick = (style) => {
        navigate('/map', { state: { filterType: 'style', filterValue: style } });
    }

    const handleRatingClick = (rating) => {
        navigate('/map', { state: { filterType: 'rating', filterValue: rating } });
    }

    return (
        <div className={classes.container}>
            <DaysChart data={days} subData={timesByDays} />
            <TimesChart data={times} />
            <StylesChart data={styles} onStyleClick={handleStyleClick} />
            <RatingsChart data={ratings} onRatingClick={handleRatingClick} />
        </div>
    );
}

export default Stats;
