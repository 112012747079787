import React from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import Markers from "./Markers";

const options = {
    gestureHandling: 'greedy',
    minZoom: 2,
    restriction: {
        latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
};

const MapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDOHGju9V8phG7bzVkB23C8Uv95uuCT0ac&signed_in=false",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100vh` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    const [map] = React.useState(null);

    const onMarkerClick = (lat, lng) => {
        if (map) {
            map.panTo({ lat, lng });
        }
    };

    return (
        <GoogleMap options={options}
                   defaultZoom={3}
                   defaultCenter={{ lat: 15, lng: -30 }}
        >
            <Markers onMarkerClick={onMarkerClick} MarkersArray={props.MarkersArray} Full={props.FullArray} />
        </GoogleMap>
    )
}
);

export default MapComponent;