import React, { Component } from 'react';
import classes from './Stats.module.css';
import CanvasJS from './canvasjs.react';
var CanvasJSChart = CanvasJS.CanvasJSChart;
var $ = require('jquery');

class DaysChart extends Component {
    constructor() {
        super();
        this.state = {
            selection: "Days"
        };
        this.DaysDrillDownHandler = this.DaysDrillDownHandler.bind(this);
        this.BackHandler = this.BackHandler.bind(this);
    }

    DaysDrillDownHandler(e) {
        this.setState({selection: e["dataPoint"]["label"]})
        $("#backButton").toggleClass(classes.invisible);
    }

    BackHandler() {
        $("#backButton").toggleClass(classes.invisible);
        this.setState({selection: "Days"})
    }

    render() {
        let daysData = null;
        if (this.props.data) {
            daysData = this.props.data.map((dt, index) => {
                return { label: dt.Dy, y: dt.Ct, color: "Indigo" }
            });
        }
        let sundayData = null;
        let mondayData = null;
        let tuesdayData = null;
        let wednesdayData = null;
        let thursdayData = null;
        let fridayData = null;
        let saturdayData = null;
        if (this.props.subData) {
            sundayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 1) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            mondayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 2) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            tuesdayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 3) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            wednesdayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 4) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            thursdayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 5) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            fridayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 6) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            saturdayData = this.props.subData.map((dt, index) => {
                if (dt.DoW === 7) {
                    return {x: new Date(null, null, null, dt.Hr, dt.Mn), y: dt.Ct, color: "Indigo"}
                }
                return undefined;
            });
            var sunday = sundayData.filter(x => { return x !== undefined; });
            var monday = mondayData.filter(x => { return x !== undefined; });
            var tuesday = tuesdayData.filter(x => { return x !== undefined; });
            var wednesday = wednesdayData.filter(x => { return x !== undefined; });
            var thursday = thursdayData.filter(x => { return x !== undefined; });
            var friday = fridayData.filter(x => { return x !== undefined; });
            var saturday = saturdayData.filter(x => { return x !== undefined; });
        }

        const daysOptions = {
            "Days": {
                title:{
                    text: "Number of Beers by Day"
                },
                axisX: {
                    title: "Day of Week"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    click: this.DaysDrillDownHandler,
                    cursor: "pointer",
                    explodeOnClick: false,
                    animationEnabled: true,
                    type: "column",
                    dataPoints: daysData
                }]
            },
            "Sunday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Sundays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: sunday
                }]
            },
            "Monday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Mondays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: monday
                }]
            },
            "Tuesday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Tuesdays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: tuesday
                }]
            },
            "Wednesday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Wednesdays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: wednesday
                }]
            },
            "Thursday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Thursdays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: thursday
                }]
            },
            "Friday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Fridays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: friday
                }]
            },
            "Saturday": {
                animationEnabled: true,
                title:{
                    text: "Number of Beers by Hour on Saturdays"
                },
                axisX: {
                    title: "Time of Day",
                    valueFormatString: "H:mm"
                },
                axisY: {
                    includeZero: true
                },
                data: [{
                    yValueFormatString: "#",
                    xValueFormatString: "H:mm",
                    type: "spline",
                    dataPoints: saturday
                }]
            }
        }

        const buttonStyle={
            borderRadius: '4px',
            padding: '8px',
            border: 'none',
            fontSize: '16px',
            backgroundColor: '#2eacd1',
            color: 'white',
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer'
        }

        return (
            <div>
                <CanvasJSChart options = {daysOptions[this.state.selection]} />
                <button className={classes.btn + ' ' + classes.invisible} id="backButton" style={buttonStyle} onClick={this.BackHandler} >&lt; Back</button>
            </div>
        );
    }
}

export default DaysChart;