import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';

const Home = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>My Beer Journey</h1>
            <div className={styles.mapSection}>
                <Link to="/map" className={styles.mapLink}>
                    <img src="https://rjbogz.com/beer/images/map.png" alt="Map" className={styles.mapImage} />
                    <p className={styles.mapCaption}>VIEW THE MAP</p>
                </Link>
            </div>
            <div className={styles.links}>
                <div className={styles.linkItemLeft}>
                    <Link to="/me" className={styles.link}>
                        <img src="https://rjbogz.com/beer/images/profile.png" alt="About Me" className={styles.linkImage} />
                        <p className={styles.imageCaption}>About Me</p>
                    </Link>
                </div>
                <div className={styles.linkItemRight}>
                    <Link to="/stats" className={styles.link}>
                        <img src="https://rjbogz.com/beer/images/stats.png" alt="Stats" className={styles.linkImage} />
                        <p className={styles.imageCaption}>Stats</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Home;
