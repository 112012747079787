import React, { Component } from 'react';
import CanvasJS from './canvasjs.react';
var CanvasJSChart = CanvasJS.CanvasJSChart;

class RatingsChart extends Component {
    render() {
        let ratingsData = null;
        if (this.props.data) {
            ratingsData = this.props.data.map((dt, index) => {
                return { x: dt.Rt, y: dt.Ct, color: "Indigo", click: () => this.props.onRatingClick(dt.Rt) }
            });
        }

        const ratingsOptions = {
            animationEnabled: true,
            title: {
                text: "Ratings"
            },
            axisX: {
                title: "Rating",
                valueFormatString: "#.##",
                maximum: 5.00
            },
            axisY: {
                includeZero: false,
            },
            data: [{
                yValueFormatString: "#",
                xValueFormatString: "#.##",
                type: "spline",
                dataPoints: ratingsData
            }]
        }

        return (
            <div>
                <CanvasJSChart options={ratingsOptions} />
            </div>
        );
    }
}

export default RatingsChart;