import React, { Component } from 'react';
import styles from "./BeerFilter.module.css";

class BeerFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ctrlIsPressed: false,
            expandedBreweries: {},
            searchQuery: '',
        };
        this.breweryRefs = {};
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    filterBeersAndBreweries = () => {
        const { searchQuery } = this.state;
        const { beersHierarchy } = this.props;
        if (!searchQuery) return beersHierarchy;

        const filteredBeersHierarchy = {};

        Object.keys(beersHierarchy).forEach(brewery => {
            const beers = beersHierarchy[brewery].filter(beer =>
                beer.toLowerCase().includes(searchQuery.toLowerCase())
            );
            if (brewery.toLowerCase().includes(searchQuery.toLowerCase()) || beers.length > 0) {
                filteredBeersHierarchy[brewery] = beersHierarchy[brewery];
            }
        });

        return filteredBeersHierarchy;
    };

    decodeHtmlEntity = (str) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    };

    handleCheckboxChange = (key, isChecked) => {
        if (key.split('|').length > 1) {
            this.props.updateSelections("beer", key, isChecked);
        } else {
            this.props.updateSelections("brewery", key, isChecked);
        }
    };

    expandCollapseBreweries = (breweryName) => {
        this.setState(prevState => {
            let newExpandedBreweries = { ...prevState.expandedBreweries };
            if (prevState.ctrlIsPressed) {
                const shouldExpandAll = !prevState.expandedBreweries[breweryName];
                Object.keys(newExpandedBreweries).forEach(brewery => {
                    newExpandedBreweries[brewery] = shouldExpandAll;
                });
            } else {
                newExpandedBreweries[breweryName] = !prevState.expandedBreweries[breweryName];
            }
            return { expandedBreweries: newExpandedBreweries };
        });
    };

    render() {
        const { expandedBreweries } = this.state;
        const { beerSelections } = this.props;
        const filteredBeersHierarchy = this.filterBeersAndBreweries();

        return (
            <div id="breweriesDiv">
                <input
                    type="text"
                    placeholder="Search Beers or Breweries"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange}
                    className={styles.searchInput}
                />
                {Object.keys(filteredBeersHierarchy).map(brewery => {
                    const brewerySelection = beerSelections[brewery] || {};
                    const isBreweryChecked = brewerySelection.checked || false;
                    const isExpanded = expandedBreweries[brewery] || false;
                    const { indeterminate } = beerSelections[brewery] || {};

                    return (
                        <div key={brewery} className={styles.brewery}>
                            <input
                                type="checkbox"
                                checked={isBreweryChecked}
                                onChange={e => this.handleCheckboxChange(`${brewery}`, e.target.checked)}
                                ref={el => { if (el) el.indeterminate = !!indeterminate; }}
                            />
                            <span onClick={() => this.expandCollapseBreweries(brewery)}>
                                {this.decodeHtmlEntity(brewery)}
                            </span>
                            <div className={isExpanded ? styles.beersVisible : styles.beersHidden}>
                                {filteredBeersHierarchy[brewery].map(beer => (
                                    <div key={beer} className={styles.beer}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={beerSelections[`${brewery}|${beer}`] ? beerSelections[`${brewery}|${beer}`].checked : false}
                                                onChange={e => this.handleCheckboxChange(`${brewery}|${beer}`, e.target.checked)}
                                                ref={el => {
                                                    if (el) el.indeterminate = beerSelections[`${brewery}|${beer}`] ? beerSelections[`${brewery}|${beer}`].indeterminate : false;
                                                }}
                                            />
                                            {this.decodeHtmlEntity(beer)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default BeerFilter;
