import React, {Component} from 'react';
import { Marker, InfoWindow } from "react-google-maps";
import Checkin from "./InfoWindow/Checkin";

class Markers extends Component {
    constructor(props){
        super(props);
        this.state = {
            openInfoWindowMarkerId: '',
            infoboxMessage: '',
            isInfoWindowVisible: false,
            markerLong: 0,
            markerLat: 0
        }
    }

    handleMarkerClick = (markerId, lat, lng) => {
        this.props.onMarkerClick(lat, lng);
        if (markerId === 0) {
            this.setState({
                openInfoWindowMarkerId: "",
            });
        } else {
            this.setState({
                openInfoWindowMarkerId: markerId,
            });
        }
    }

    render() {
        var groupBy = function(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        return(
            Object.entries(groupBy(this.props.Full, 'LatLong')).map((value, key) => {
                let c = ' checkin';
                if (value[1].length > 1) c = ' checkins';
                return (
                    <Marker key={key} position={{lat: Number(value[1][0].Latitude), lng: Number(value[1][0].Longitude)}}
                            title={value[1][0].Location + ' - ' + value[1].length + c}
                            onClick={() => this.handleMarkerClick(key, Number(value[1][0].Latitude), Number(value[1][0].Longitude))}
                    >
                        {(this.state.openInfoWindowMarkerId === key) && (
                            <InfoWindow onCloseClick={() => this.handleMarkerClick(0)} options= {{maxWidth : 400}}>
                                <Checkin checkins={value} />
                            </InfoWindow>
                        )}
                    </Marker>
                )
            })
        );
    }
}

export default Markers;