import React, { Component } from 'react';
import styles from "./LocationFilter.module.css";

class LocationFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedCountries: {},
            expandedStates: {},
            expandedCities: {},
            searchQuery: '',
        };
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    filterLocations = () => {
        const { searchQuery } = this.state;
        const { locationsHierarchy } = this.props;
        if (!searchQuery) return locationsHierarchy;

        const filteredLocationsHierarchy = {};

        Object.keys(locationsHierarchy).forEach(country => {
            const states = locationsHierarchy[country];
            const filteredStates = {};

            Object.keys(states).forEach(state => {
                const cities = states[state];
                const filteredCities = {};

                Object.keys(cities).forEach(city => {
                    const locations = cities[city].filter(location =>
                        location.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                    if (city.toLowerCase().includes(searchQuery.toLowerCase()) || locations.length > 0) {
                        filteredCities[city] = locations;
                    }
                });

                if (state.toLowerCase().includes(searchQuery.toLowerCase()) || Object.keys(filteredCities).length > 0) {
                    filteredStates[state] = filteredCities;
                }
            });

            if (country.toLowerCase().includes(searchQuery.toLowerCase()) || Object.keys(filteredStates).length > 0) {
                filteredLocationsHierarchy[country] = filteredStates;
            }
        });

        return filteredLocationsHierarchy;
    };

    sortDataAlphabetically = (data) => {
        return Object.entries(data).sort(([key1], [key2]) => key1.localeCompare(key2));
    };

    toggleExpand = (key) => {
        const levelKeys = { 1: 'expandedCountries', 2: 'expandedStates', 3: 'expandedCities' };
        const level = key.split('|').length;
        const stateKey = levelKeys[level];

        this.setState(prevState => ({
            [stateKey]: {
                ...prevState[stateKey],
                [key]: !(prevState[stateKey] && prevState[stateKey][key])
            }
        }));
    };

    handleCheckboxChange = (key, isChecked) => {
        if (key.split('|').length === 1) {
            this.props.updateSelections("country", key, isChecked);
        } else if (key.split('|').length === 2) {
            this.props.updateSelections("state", key, isChecked);
        } else if (key.split('|').length === 3) {
            this.props.updateSelections("city", key, isChecked);
        } else if (key.split('|').length >= 4) {
            this.props.updateSelections("location", key, isChecked);
        }
    };


    renderCheckbox = (key, label) => {
        const levelKeys = { 1: 'expandedCountries', 2: 'expandedStates', 3: 'expandedCities' };
        const { checked, indeterminate } = this.props.locationSelections[key] || {};
        const level = key.split('|').length;
        const levelClass = ['country', 'state', 'city', 'location'][level - 1];
        const isExpanded = this.state[levelKeys[level]] && this.state[levelKeys[level]][key];

        return (
            <div key={key} className={`${styles[levelClass]}`}>
                <input
                    type="checkbox"
                    id={`checkbox-${key}`}
                    className={styles.checkbox}
                    checked={checked || false}
                    ref={el => { if (el) el.indeterminate = !!indeterminate; }}
                    onChange={e => this.handleCheckboxChange(key, e.target.checked)}
                />
                <span
                    htmlFor={`checkbox-${key}`}
                    className={`${styles.checkboxLabel} ${styles[`${levelClass}Checkbox`]}`}
                    onClick={(e) => {
                        e.preventDefault();
                        this.toggleExpand(key);
                    }}
                >
                    {label}
                </span>
                {isExpanded && <div className={isExpanded ? styles.visible : styles.hidden}>{this.renderChildren(key, level)}</div>}
            </div>
        );
    };

    renderChildren = (parentKey, level) => {
        const keys = parentKey.split('|');
        let childrenElements = [];

        if (level === 1) {
            // Sort states
            const sortedStates = this.sortDataAlphabetically(this.filterLocations()[keys[0]]);
            childrenElements = sortedStates.map(([state, cities]) => {
                return this.renderCheckbox(`${parentKey}|${state}`, state);
            });
        } else if (level === 2) {
            // Sort cities
            const sortedCities = this.sortDataAlphabetically(this.filterLocations()[keys[0]][keys[1]]);
            childrenElements = sortedCities.map(([city, locations]) => {
                return this.renderCheckbox(`${parentKey}|${city}`, city);
            });
        } else if (level === 3) {
            // Sort locations
            let locations = this.filterLocations()[keys[0]][keys[1]][keys[2]];
            const sortedLocations = locations.sort((a, b) => {
                const nameA = a.split('^')[2] || 'Unknown location';
                const nameB = b.split('^')[2] || 'Unknown location';
                return nameA.localeCompare(nameB);
            });

            sortedLocations.forEach(location => {
                const parts = location.split('|');
                const childKey = parts[3];
                const nameParts = childKey.split('^');
                const name = nameParts[2] || 'Unknown location';
                childrenElements.push(this.renderCheckbox(`${parentKey}|${childKey}`, name));
            });
        }

        return childrenElements;
    };

    render() {
        const sortedCountries = this.sortDataAlphabetically(this.filterLocations());
        return (
            <div className={styles.locationFilter}>
                <input
                    type="text"
                    placeholder="Search Locations"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange}
                    className={styles.searchInput}
                />
                {sortedCountries.map(([country]) => this.renderCheckbox(country, country))}
            </div>
        );
    }
}

export default LocationFilter;
