import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutMe from "./AboutMe/AboutMe";
import Stats from "./Stats/Stats";
import Home from "./Home/Home";
import Map from "./Map/Map";
import NotFound from "./NotFound";

class App extends Component {

    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/me" element={<AboutMe />} />
                    <Route path="/stats" element={<Stats />} />
                    <Route path="/map" element={<Map />} />
                    <Route exact path="/" element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        );
    }
}

export default App;
